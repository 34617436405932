<template>
    <tr @click="startEditing">
      <td>{{ name }}</td>
      <td>{{ company.cnpj }}</td>
      <td>{{ type }}</td>
      <td>{{ address_city }}</td>
      <td>{{ active }}</td>
    </tr>
  </template>
  
  <script>
  import moment from 'moment';
  import { CompanyTypesName } from '../../constants/CompanyTypes';
  
  export default {
    props: ['company'],
    computed: {
      cnpj() {
        return this.company.cnpj;
      },
      type() {
        return CompanyTypesName[this.company.type] ?? "Não definido";
      },
      name() {
        return this.company.corporate_reason;
      },

      address_city() {
        return this.company.address_city;
      },
      active() {
        return this.company.active ? "Sim" : "Não";
      },
      created_at() {
        return moment(this.company.created_at).format("DD/MM/YYYY HH:mm");
      }
    },
    methods: {
      startEditing() {
        this.$store.dispatch('companies/startEditing', this.company);
      }
    }
  }
  </script>
  
  <style scoped>
  /* Estilos específicos podem ser adicionados aqui, se necessário */
  </style>
  