<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <!-- Card Header -->
            <div class="pb-0 card-header">
              <div class="d-lg-flex justify-content-between align-items-center">
                <h5 class="mb-0">Leads</h5>
                <div class="d-flex align-items-center mt-4 mt-lg-0">
                    <input
                        type="text"
                        class="form-control"
                        v-model="searchQuery"
                        @input="searchLeads"
                        placeholder="Buscar..."
                    />&nbsp;&nbsp;
                  <a @click="startAdding" class="mb-0 btn bg-gradient-success btn-sm me-2">
                    + Novo Lead
                  </a>
                </div>
              </div>
            </div>
  
            <!-- Loading Spinner -->
            <div class="spinner-border" role="status" v-if="loading">
              <span class="sr-only">Carregando...</span>
            </div>
  
            <!-- Card Body -->
            <div class="px-0 pb-0 card-body" v-else>
              <div class="table-responsive">
                <table id="leads-list" ref="leadsList" class="table table-flush">
                  <thead class="thead-light">
                    <tr>
                      <!-- Cabeçalho para ordenar por nome -->
                      <th @click="sortByName" class="click">
                        <strong>
                          Nome&nbsp;
                          <span>
                            <i :class="{
                                'fa fa-chevron-up': sortNameOrder === 1,
                                'fa fa-chevron-down': sortNameOrder === 2,
                                'fa fa-bars': sortNameOrder === 0
                              }"></i>
                          </span>
                        </strong>
                      </th>
                      <th @click="sortByOrigin" class="click">
                        <strong>
                          Origem&nbsp;
                          <span>
                            <i :class="{
                                'fa fa-chevron-up': sortOriginOrder === 1,
                                'fa fa-chevron-down': sortOriginOrder === 2,
                                'fa fa-bars': sortOriginOrder === 0
                              }"></i>
                          </span>
                        </strong>
                      </th>
                      <th @click="sortByFunnel" class="click">
                        <strong>
                          Funil&nbsp;
                          <span>
                            <i :class="{
                                'fa fa-chevron-up': sortFunnelOrder === 1,
                                'fa fa-chevron-down': sortFunnelOrder === 2,
                                'fa fa-bars': sortFunnelOrder === 0
                              }"></i>
                          </span>
                        </strong>
                      </th>
                      <th><strong>Região</strong></th>
                    </tr>
                  </thead>
                  <tbody class="text-sm">
                    <lead-tile v-for="(lead, index) in sortedLeads" :lead="lead" :key="index" />
                  </tbody>
                </table>
              </div>
            </div>
  
            <pagination 
              :current="page"
              :perPage="perPage"
              :onChangePage="changeToPage"
              :totalPages="lastPage"
              :totalValues="total"
            /> 
          </div>
        </div>
      </div>
      <lead-modal />
    </div>
</template>
  
<script>
import LeadTile from "./components/LeadTile.vue";
import LeadModal from "./components/LeadModal.vue";
import { mapState } from 'vuex';
import Pagination from "../components/Pagination.vue";
import list_mixin from "../mixins/list_mixin";
  
export default {
    name: "Leads",
    components: {
        LeadTile,
        LeadModal,
        Pagination
    },

    mixins: [list_mixin('leads')],

    data() {
        return {
            searchQuery: "",
            sortNameOrder: 0,
            sortOriginOrder: 0,
            sortFunnelOrder: 0
        };
    },

    computed: {
        ...mapState('leads', {
            leads: state => state.leads,
        }),

        filteredLeads() {
            return this.leads.filter((lead) => {
                const leadName = lead.name ? lead.name.toLowerCase() : '';
                return leadName.includes(this.searchQuery.toLowerCase());
            });
        },

        sortedLeads() {
            let sorted = [...this.filteredLeads];

            if (this.sortNameOrder !== 0) {
                sorted.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
                if (this.sortNameOrder === 2) sorted.reverse();
            }

            if (this.sortOriginOrder !== 0) {
                sorted.sort((a, b) => {
                    const originA = a.origin || '';
                    const originB = b.origin || '';
                    return originA.localeCompare(originB);
                });
                if (this.sortOriginOrder === 2) sorted.reverse();
            }

            if (this.sortFunnelOrder !== 0) {
                sorted.sort((a, b) => {
                    const funnelA = a.funnel || '';
                    const funnelB = b.funnel || '';
                    return funnelA.localeCompare(funnelB);
                });
                if (this.sortFunnelOrder === 2) sorted.reverse();
            }

            return sorted;
        }
    },

    methods: {
        startAdding() {
            this.$store.dispatch('leads/startAdding');
        },

        sortByName() {
            this.sortNameOrder = (this.sortNameOrder + 1) % 3;
            this.sortOriginOrder = 0;
            this.sortFunnelOrder = 0;
        },

        sortByOrigin() {
            this.sortOriginOrder = (this.sortOriginOrder + 1) % 3;
            this.sortNameOrder = 0;
            this.sortFunnelOrder = 0;
        },

        sortByFunnel() {
            this.sortFunnelOrder = (this.sortFunnelOrder + 1) % 3;
            this.sortNameOrder = 0;
            this.sortOriginOrder = 0;
        },
    },

    async created() {
        try {
            await this.$store.dispatch("leads/getLeads");
        } catch (error) {
            console.error(error);
        }
    },
};
</script>
  
<style scoped>
td {
    padding: 12px 20px;
}

thead th {
    font-weight: bold;
}

.table tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
}

.table tbody tr:nth-child(even) {
    background-color: #e0f7fa;
}

.click {
    cursor: pointer;
}
</style>
