<template>
    <tr @click="startEditing">
        <td>{{ lead.name }}</td>
        <td>{{ lead.source }}</td>
        <td>{{ lead.funnel_stage }}</td>
        <td>{{ lead.city }}</td>
    </tr>
</template>

<script>
import moment from 'moment';

export default {
    props: ['lead'],
    data() {
        return {
            name: this.lead.name,
            email: this.lead.email,
            phone: this.lead.phone || 'Não informado',
            source: this.lead.source || 'Não informado',
            status: this.getLeadStatus(this.lead.status),
            funnel_stage: this.lead.funnel_stage || 'Não informado',
            state: this.lead.state || 'Não informado',  
            city: this.lead.city || 'Não informado',    
            zone: this.lead.zone || 'Não informado',   
            created_at: moment(this.lead.created_at).format("DD/MM/YYYY HH:mm")
        };
    },
    methods: {
        startEditing() {
            this.$store.dispatch('leads/startEditing', this.lead);
        },
        getCompanyName(companyId) {
            const company = this.$store.state.companies.options.find(c => c.id === companyId);
            return company ? company.name : 'Não informado';
        },
        getAssignedUserName(userId) {
            const user = this.$store.getters["users/options"].find(u => u.id === userId);
            return user ? user.name : 'Não informado';
        },
        getLeadStatus(status) {
            const statusMapping = {
                'new': 'Novo',
                'in_progress': 'Em Progresso',
                'closed': 'Concluído'
            };
            return statusMapping[status] || 'Desconhecido';
        }
    }
};
</script>
