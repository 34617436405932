import api from "./axios";

export default {
    async getExams(patientId) {
        const response = await api.get(`/patients/${patientId}/exams`);
        return response.data;
    },
    async addExam(patientId, exam) {
        const formData = new FormData();
        for (const key in exam) {
            formData.append(key, exam[key]);
        }
        const response = await api.post(`/patients/${patientId}/exams`, exam, {
            headers: { "Content-Type": "multipart/form-data" }
        })

        return response.data;
    },
    async updateExam(patientId, exam) {
        const formData = new FormData();
        for (const key in exam) {
            if (exam[key]) formData.append(key, exam[key]);
        }
        const response = await api.put(`/patients/${patientId}/exams`, exam, {
            headers: { "Content-Type": "multipart/form-data" }
        })

        return response.data;
    },
}
