import api from "./axios";

export default {
    async getLeadActivities(page = 1) {
        const response = await api.get(`/lead-activities?page=${page}`);
        return response.data;
    },
    async getLeadActivitiesByLeadId(leadId) {
        const response = await api.get(`/lead-activities?lead_id=${leadId}`);
        return response.data;
    },
    async getAllLeadActivities() {
        const response = await api.get(`/lead-activities?all=true`);
        return response.data;
    },
    async addLeadActivity(leadActivity) {
        const response = await api.post('/lead-activities', leadActivity);
        return response.data;
    },
    async updateLeadActivity(leadActivity) {
        const response = await api.put(`/lead-activities/${leadActivity.id}`, leadActivity);
        return response.data;
    },
    async deleteLeadActivity(leadActivity) {
        const response = await api.delete(`/lead-activities/${leadActivity}`);
        return response.data;
    }
};