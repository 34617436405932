<template>
  <div class="modal fade" id="company_modal" tabindex="-1" data-bs-keyboard="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal_demo_label">{{ title }}</h5>
        </div>
        <div class="modal-body">
          <Form
            id="myForm"
            ref="form"
            role="form"
            class="text-start"
            :validation-schema="schema"
            @submit="handleSubmit"
          >
            <ul class="nav nav-tabs mb-3" id="ex1" role="tablist">
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link active"
                  id="data-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#data"
                  role="tab"
                  aria-controls="data"
                  aria-selected="true"
                  >Dados</a
                >
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="phone-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#phone"
                  role="tab"
                  aria-controls="phone"
                  aria-selected="false"
                  >Telefones</a
                >
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="email-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#email"
                  role="tab"
                  aria-controls="email"
                  aria-selected="false"
                  >E-mails</a
                >
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="social_media-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#social_media"
                  role="tab"
                  aria-controls="social_media"
                  aria-selected="false"
                  >Mídias Sociais</a
                >
              </li>
            </ul>

            <div class="tab-content" id="ex1-content">
              <company-data-tab v-model="state" />
              <company-phones-tab v-model="state" />
              <company-emails-tab v-model="state" />
              <company-social-medias-tab v-model="state" />
            </div>
          </Form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="stopEditing"
          >Cancelar</button>
          <button
            type="submit"
            form="myForm"
            class="btn btn-success"
            :disabled="loading ? true : false"
          >
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span v-else>Salvar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap";

import { CompanyTypesOptions } from "../../constants/CompanyTypes";
import showSwal from "../../mixins/showSwal";
import * as yup from "yup";
import { mapState } from "vuex";
import formModalMixin from "../../mixins/form-modal-mixin";
import "vue-search-select/dist/VueSearchSelect.css"

import CompanyDataTab from "./CompanyDataTab.vue";
import CompanyPhonesTab from "./CompanyPhonesTab.vue";
import CompanyEmailsTab from "./CompanyEmailsTab.vue";
import CompanySocialMediasTab from "./CompanySocialMediasTab.vue";
import { Form } from "vee-validate";

const getState = (company) => ({
  id: company?.id,
  cnpj: company?.cnpj ?? '',
  type: company?.type ?? '',
  corporate_reason: company?.corporate_reason ?? '',
  zip_code: company?.zip_code ?? '',
  address_street: company?.address_street ?? '',
  address_number: company?.address_number ?? '',
  address_neighborhood: company?.address_neighborhood ?? '',
  address_complement: company?.address_complement ?? '',
  address_city: company?.address_city ?? '',
  address_state: company?.address_state ?? '',
  address_country: company?.address_country ?? '',
  site: company?.site ?? '',
  healthcare_operator_id: company?.healthcare_operator_id ?? '',
  active: company?.active ?? true,
  phones: company?.phones ?? [],
  emails: company?.emails ?? [],
  social_medias: company?.social_medias ?? [],
})

export default {
  name: "CompanyModal",
  mixins: [formModalMixin('companies')],
  components: {
    Form,
    CompanyDataTab,
    CompanyPhonesTab,
    CompanyEmailsTab,
    CompanySocialMediasTab
  },
  mounted() {
    this.company_modal = new bootstrap.Modal('#company_modal', {
      backdrop: 'static'
    });
  },
  data() {
    const schema = yup.object().shape({
      cnpj: yup.string().required().label("CNPJ"),
      type: yup.string().required().label("Tipo"),
      corporate_reason: yup.string().required().label("Razão Social"),
      zip_code: yup.number().required().label("CEP"),
      address_street: yup.string().required().label("Rua"),
      address_number: yup.number().required().label("Número"),
      address_neighborhood: yup.string().required().label("Bairro"),
      address_complement: yup.string().label("Complemento"),
      address_city: yup.string().required().label("Cidade"),
      address_state: yup.string().required().label("Estado"),
      address_country: yup.string().required().label("País"),
      site: yup.string().required().label("Site"),
      phones: yup.array().of(
        yup.object().shape({
          contact_name: yup.string().required().label("Nome para contato"),
          department: yup.number().required().label("Departamento")
            .typeError("${label} é obrigatório"),
          phone: yup.string().required().label("Telefone"),
          extension: yup.string().required().label("Ramal"),
        })
      ),
      emails: yup.array().of(
        yup.object().shape({
          contact_name: yup.string().required().label("Nome para contato"),
          department: yup.number().required().label("Departamento")
            .typeError("${label} é obrigatório"),
          email: yup.string().email().required().label("Email"),
        })
      ),
      social_medias: yup.array().of(
        yup.object().shape({
          name: yup.string().required().label("Nome"),
          social_media: yup.string().required().label("Mídia Social"),
        })
      ),
    });

    return {
      schema,
      loading: false,
      CompanyTypesOptions,
      state: getState(null)
    }
  },
  computed: {
    ...mapState('companies', {
      open: state => state.form.open,
      company: state => state.form.company,
      title: state => state.form.company?.name ?? "Nova Empresa",
    }),
  },
  watch: {
    open (newOpen) {
      this.resetForm();
      if (newOpen) this.openCompanyModal();
      else this.closeCompanyModal();
    },
  },
  async created() {
    try {
        await this.$store.dispatch("healthcareOperators/getOptions");
      
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    async handleSubmit() {
      this.loading = true
      try {
        if (this.company) await this.$store.dispatch('companies/updateCompany', this.state);
        else 
        await this.$store.dispatch('companies/addCompany', this.state);
        if (this.error == null){
          showSwal.methods.showSwal({
              type:"success",
              message: "Empresa cadastrada com sucesso!",
              timer: 5000
          })
        }
        this.loading = false

      } catch (error) {
        this.loading = false;
      }
    },
    stopEditing() {
      this.$store.dispatch('companies/stopEditing');
    },
    openCompanyModal() {
      this.company_modal.show()
    },
    closeCompanyModal() {
      this.company_modal.hide()
    },
    resetForm() {
      this.state = getState(this.company)
      this.$refs.form.resetForm();
    },
  },
}
</script>