<template>
  <div class="modal fade" id="lead_modal" tabindex="-1" data-bs-keyboard="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal_demo_label">{{ title }}</h5>
        </div>
        <div class="modal-body">
          <Form
            id="myForm"
            ref="form"
            role="form"
            class="text-start"
            :validation-schema="schema"
            @submit="handleSubmit"
          >
            <ul class="nav nav-tabs mb-3" id="ex1" role="tablist">
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link active"
                  id="data-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#data"
                  role="tab"
                  aria-controls="data"
                  aria-selected="true"
                  >Dados</a
                >
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="activity-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#activity"
                  role="tab"
                  aria-controls="activity"
                  aria-selected="false"
                  >Atividades</a
                >
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="history-activity-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#history-activity"
                  role="tab"
                  aria-controls="history-activity"
                  aria-selected="false"
                  >Histórico</a
                >
              </li>
            </ul>

            <div class="tab-content" id="ex1-content">
              <lead-data-tab v-model="state" />
              <lead-activity-tab v-model="activities" :lead-id="lead?.id" />
              <lead-history-activity-tab v-model="historyActivies" :lead-id="lead?.id" />
            </div>
          </Form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="stopEditing"
          >Cancelar</button>
          <button
            type="submit"
            form="myForm"
            class="btn btn-success"
            :disabled="loading ? true : false"
          >
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span v-else>Salvar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
  import bootstrap from "bootstrap/dist/js/bootstrap";
  import showSwal from "../../mixins/showSwal";
  import * as yup from "yup";
  import { mapState } from "vuex";
  import formModalMixin from "../../mixins/form-modal-mixin";
  import { Form } from "vee-validate";
  import LeadDataTab from "./LeadDataTab.vue";
  import LeadActivityTab from "./LeadActivityTab.vue";
  import LeadHistoryActivityTab from "./LeadHistoryActivityTab.vue";
  
  const getState = (lead) => ({
    id: lead?.id,
    name: lead?.name ?? '',
    email: lead?.email ?? '',
    phone: lead?.phone ?? '',
    state: lead?.state ?? '',
    zone: lead?.zone ?? '',
    city: lead?.city ?? '',
    company_id: lead?.company_id ?? '',
    source: lead?.source ?? '',
    funnel_stage: lead?.funnel_stage ?? '',
    assigned_user_id: lead?.assigned_user_id ?? '',
    notes: lead?.notes ?? '',
});
  
export default {
  name: "LeadModal",
  mixins: [formModalMixin('leads')],
  components: {
    Form,
    LeadDataTab,
    LeadActivityTab,
    LeadHistoryActivityTab
  },
  mounted() {
    this.lead_modal = new bootstrap.Modal('#lead_modal', {
      backdrop: 'static'
    });
  },
  data() {
      const schema = yup.object().shape({
          name: yup.string().required("O nome é obrigatório."),
          email: yup.string().email("Email inválido").required("O email é obrigatório."),
          phone: yup.string().nullable().max(20, "O telefone deve ter no máximo 20 caracteres."),
          state: yup.string().nullable().max(35, "Informe o estado corretamente"),
          city: yup.string().nullable().max(35, "Informe a cidade corretamente"),
          zone: yup.string().nullable().max(40, "Informe de que região você é"),
          company_id: yup.string().nullable().matches(/^\d+$/, "O ID da empresa deve ser numérico."),
          source: yup.string().nullable().max(255, "A origem deve ter no máximo 255 caracteres."),
          funnel_stage: yup.string().nullable().max(50, "A fase do funil deve ter no máximo 50 caracteres."),
          assigned_user_id: yup.string().nullable().matches(/^\d+$/, "O ID do usuário atribuído deve ser numérico."),
          notes: yup.string().nullable(),
      });

    return {
      schema,
      loading: false,
      state: getState(null),
      activities: [],
    }
  },
  computed: {
    ...mapState('leads', {
      open: state => state.form.open,
      lead: state => state.form.lead,
      title: state => state.form.lead?.name ?? "Novo Lead",
    }),
  },
  watch: {
    open (newOpen) {
      this.resetForm();
      if (newOpen) this.openLeadModal();
      else this.closeLeadModal();
    },
  },
  async created(){
    try {
      await this.$store.dispatch("companies/getCompanies");
      // console.log('retorno do companies: ', this.$store.state.companies)

      await this.$store.dispatch("users/getUsers")
      // console.log('retorno do users ', this.$store.state.users)

    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    async handleSubmit() {
      this.loading = true
      try {
        if (this.lead) {
          await this.$store.dispatch('leads/updateLead', this.state);
          showSwal.methods.showSwal({
            type: "success",
            message: "Lead atualizado com sucesso!",
            timer: 5000
          });
        } else { 
          await this.$store.dispatch('leads/addLead', this.state);
          if (this.error == null){
            showSwal.methods.showSwal({
                type:"success",
                message: "Lead cadastrado com sucesso!",
                timer: 5000
            });
          }
        } 
      } catch (error) {
        console.error("Erro ao salvar usuário:", error);
        this.$swal({
          title: "Erro",
          text: "Ocorreu um erro ao salvar o usuário.",
          icon: "error"
        });
      } finally {
        this.loading = false;
      }
    },
    stopEditing() {
      this.$store.dispatch('leads/stopEditing');
    },
    openLeadModal() {
      this.lead_modal.show()
    },
    closeLeadModal() {
      this.lead_modal.hide()
    },
    resetForm() {
      this.state = getState(this.lead)
      this.$refs.form.resetForm();
    },
  },
}
</script>