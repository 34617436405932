<template>
  <div
    class="tab-pane fade show active"
    id="data"
    role="tabpanel"
    aria-labelledby="data-tab"
  >
    <label for="cnpj">CNPJ<span class="required">*</span></label>
    <soft-field
      id="cnpj"
      v-model="company.cnpj"
      placeholder="CNPJ"
      name="cnpj"
    />
    
    <label>Tipo<span class="required">*</span></label>
    <soft-select
      id="type"
      name="type"
      v-model="company.type"
      :options="CompanyTypesOptions"
      placeholder="Selecione o tipo"
    />

    <label for="corporate_reason">Razão Social<span class="required">*</span></label>
    <soft-field
      id="corporate_reason"
      v-model="company.corporate_reason"
      placeholder="Razão social"
      name="corporate_reason"
    />
    
    <label for="site">Site<span class="required">*</span></label>
    <soft-field
      id="site"
      v-model="company.site"
      placeholder="Site"
      name="site"
    />
    <label>Operadora de Saúde</label>
    <soft-select-search
      id="healthcare_operator_id"
      name="healthcare_operator_id"
      v-model="company.healthcare_operator_id"
      :options="healthcareOperators"
      placeholder="Selecione a operadora de saúde"
    />

    <div class="form-group mt-3">
      <soft-switch-model 
        id="active"
        name="active"
        v-model="company.active"
      >
        Ativo
      </soft-switch-model>
    </div>
    
    <h3>Endereço</h3>

    <label for="zip_code">CEP (Somente Números)<span class="required">*</span></label>
    <soft-field
      id="zip_code"
      v-model="company.zip_code"
      placeholder="CEP"
      name="zip_code"
      @input="handleCEPChange"
      debounce="500"
    />    

    <label for="address_street">Rua<span class="required">*</span></label>
    <soft-field
      id="address_street"
      v-model="company.address_street"
      placeholder="Rua"
      name="address_street"
    />
    
    <label for="address_number">Número<span class="required">*</span></label>
    <soft-field
      id="address_number"
      v-model="company.address_number"
      placeholder="Número"
      name="address_number"
    />
    
    <label for="address_neighborhood">Bairro<span class="required">*</span></label>
    <soft-field
      id="address_neighborhood"
      v-model="company.address_neighborhood"
      placeholder="Bairro"
      name="address_neighborhood"
    />
    
    <label for="address_complement">Complemento</label>
    <soft-field
      id="address_complement"
      v-model="company.address_complement"
      placeholder="Complemento"
      name="address_complement"
    />
    
    <label for="address_city">Cidade<span class="required">*</span></label>
    <soft-field
      id="address_city"
      v-model="company.address_city"
      placeholder="Cidade"
      name="address_city"
    />
    
    <label for="address_state">Estado<span class="required">*</span></label>
    <soft-field
      id="address_state"
      v-model="company.address_state"
      placeholder="Estado"
      name="address_state"
    />
    
    <label for="address_country">País<span class="required">*</span></label>
    <soft-field
      id="address_country"
      v-model="company.address_country"
      placeholder="País"
      name="address_country"
    />
  </div>
</template>

<script>
  import SoftSwitchModel from "../../components/SoftSwitchModel.vue";
  import SoftSelect from "../../components/SoftSelect";
  import "vue-search-select/dist/VueSearchSelect.css"
  import { CompanyTypesOptions } from "../../constants/CompanyTypes";
  import SoftField from "../../components/SoftField.vue";
  import SoftSelectSearch from "../../components/SoftSelectSearch.vue";

  export default {
    name: "CompanyDataTab",
    props: ['modelValue'],
    components: {
      SoftField,
      SoftSwitchModel,
      SoftSelect,
      SoftSelectSearch,
    },
    data() {
      return { 
        CompanyTypesOptions
      }
    },
    computed: {
      company: {
        get() {
          return this.modelValue
        },
        set(newValue) {
          this.$emit('update:modelValue', newValue)
        },
      },
      healthcareOperators() {
        return this.$store.state.healthcareOperators.options;
      }
    },
    methods: {
      async fetchAddressByCEP(cep) {
        try {
          const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
          const data = await response.json();
          if (response.ok) {
            this.company.address_street = data.logradouro;
            this.company.address_neighborhood = data.bairro;
            this.company.address_city = data.localidade;
            this.company.address_state = data.uf;
            this.company.address_country = 'Brasil';
          } else {
            console.error('Erro ao buscar o CEP:', data.message);
          }
        } catch (error) {
          console.error('Erro na requisição:', error);
        }
      },
      handleCEPChange() {
        if (this.company.zip_code && this.company.zip_code.length === 8) {
          this.fetchAddressByCEP(this.company.zip_code);
        }
      },
    },
  };
</script>
