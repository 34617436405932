import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL + '/patients';

export default {
    async getPatients(page = 1, per_page = 10) {
        const url = `${API_URL}?page=${page}&per_page=${per_page}`;
        const response = await axios.get(url, {
            headers: authHeader()
        });
        return response.data;
    },
    async findPatients(query, page = 1, per_page = 10) {
        const url = `${API_URL}`;
        const response = await axios.get(url, {
            params: {
                query: query,
                page: page,
                per_page: per_page
            },
            headers: authHeader()
        });
        return response.data;
    },
    async addPatient(patient) {
        const response = await axios.post(API_URL, patient, {
            headers: authHeader()
        });
        return response.data;
    },
    async updatePatient(patient) {
        const url = `${API_URL}/${patient.id}`;
        const response = await axios.put(url, patient, {
            headers: authHeader()
        });
        return response.data;
    },
};
