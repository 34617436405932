<template>
  <div class="modal fade" id="equipaments_modal" tabindex="-1" data-bs-keyboard="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal_demo_label">{{ title }}</h5>
        </div>
        <div class="modal-body">
          <Form
            id="myForm"
            ref="form"
            role="form"
            class="text-start"
            :validation-schema="schema"
            @submit="handleSubmit"
          >
            <ul class="nav nav-tabs mb-3" id="ex1" role="tablist">
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link active"
                  id="data-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#data"
                  role="tab"
                  aria-controls="data"
                  aria-selected="true"
                >Dados</a>
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="history-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#history"
                  role="tab"
                  aria-controls="history"
                  aria-selected="false"
                >Histórico de Uso</a>
              </li>
            </ul>

            <div class="tab-content" id="ex1-content">
              <EquipamentsDataTab v-model="state" />
              <HistoryEquipamentsTab v-model="state" />
            </div>
          </Form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="stopEditing">Cancelar</button>
          <button type="submit" form="myForm" class="btn btn-success" :disabled="loading">
            <span v-if="loading" class="spinner-border spinner-border-sm"></span>
            <span v-else>Salvar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap";
import "vue-search-select/dist/VueSearchSelect.css";
import { mapState } from "vuex";
import showSwal from "../../mixins/showSwal";
import formModalMixin from "../../mixins/form-modal-mixin";
import { Form } from "vee-validate";
import { EquipamentsTypesOptions } from "../../constants/EquipamentsTypes";
import EquipamentsDataTab from "./EquipamentsDataTab.vue";
import HistoryEquipamentsTab from "./HistoryEquipamentsTab.vue";

const getState = (equipaments = {}) => ({
  id: equipaments?.id,
  equipament_type: equipaments?.equipament_type ?? '',
  mark: equipaments?.mark ?? '',
  model: equipaments?.model ?? '',
  serial_number: equipaments?.serial_number ?? '',
  active_number: equipaments?.active_number ?? '',
  obs: equipaments?.obs ?? '',
  invoice_img: equipaments?.invoice_img ?? null,
  insurance_img: equipaments?.insurance_img ?? null, 
  historyEquipaments: equipaments?.historyEquipaments ?? [],
  withdrawal_date: equipaments?.withdrawal_date ?? '',
  trd: '',
});

export default {
  name: "EquipamentsModal",
  mixins: [formModalMixin('equipaments')],
  components: {
    Form,
    EquipamentsDataTab,
    HistoryEquipamentsTab,
  },
  mounted() {
    this.equipaments_modal = new bootstrap.Modal('#equipaments_modal', {
      backdrop: 'static'
    });
  },
  data() {
    return {
      EquipamentsTypesOptions,
      loading: false,
      state: getState(this.equipaments || {})
    };
  },
  computed: {
    ...mapState('equipaments', {
      open: state => state.form.open,
      equipaments: state => state.form.equipaments,
      title: state => (state.form.equipaments?.name ?? "Novo Equipamento"),
    }),
  },
  watch: {
    open(newOpen) {
      this.resetForm();
      if (newOpen) this.openEquipamentsModal();
      else this.closeEquipamentsModal();
    },
    equipaments(newEquipaments) {
      this.state = getState(newEquipaments);
    },
  },
  methods: {
    async handleSubmit() {
      this.loading = true;
      try {
        if (this.equipaments) {
          await this.$store.dispatch('equipaments/updateEquipaments', this.state);
          showSwal.methods.showSwal({
            type: "success",
            message: "Equipamento atualizado com sucesso!",
            timer: 5000
          });
        } else {
        await this.$store.dispatch('equipaments/addEquipaments', this.state);
        if (this.error == null){
          showSwal.methods.showSwal({
              type:"success",
              message: "Equipamento cadastrado com sucesso!",
              timer: 5000
          })
        }
      }
        this.loading = false;

      } catch (error) {
        this.loading = false;
        console.error(error);
      }
    },
    openEquipamentsModal() {
      this.equipaments_modal.show();
    },
    closeEquipamentsModal() {
      this.equipaments_modal.hide();
    },
    stopEditing() {
      this.closeEquipamentsModal();
      this.$router.go();
    },
    resetForm() {
      this.state = getState(this.equipaments)
      this.$refs.form.resetForm();
    },
  },
};
</script>
