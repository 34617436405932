<template>
  <div class="modal fade" id="supports_modal" tabindex="-1" data-bs-keyboard="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal_demo_label">{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <Form
            id="myForm"
            ref="form"
            role="form"
            class="text-start"
            :validation-schema="schema"
            @submit="handleSubmit"
          >
            <label>Qual equipamento será utilizado?<span class="required">*</span></label>
            <soft-select-search
              id="equipament_type"
              name="equipament_type"
              v-model="state.equipament_type"
              :options="EquipamentsTypesOptions"
              placeholder="Selecione o equipamento"
              required
            />

            <label>Para qual empresa será prestado o serviço?<span class="required">*</span></label>
            <soft-select-search
              id="company_id"
              name="company_id"
              v-model="state.company_id"
              :options="companies"
              placeholder="Selecione a empresa"
              required
            />

            <label>Qual técnico prestará o serviço?<span class="required">*</span></label>
            <soft-select-search
              id="user_id"
              name="user_id"
              v-model="state.user_id"
              :options="users"
              placeholder="Selecione o técnico"
              required
            />

            <label for="date">Data do Atendimento<span class="required">*</span></label>
            <soft-date-time-picker
              id="date"
              v-model="state.date"
              placeholder="Selecione a Data"
              name="supportcall"
              format="dd/MM/yyyy"
              model-type="yyyy-MM-dd"
              :enable-time-picker="false"
              required
            />

            <label for="supportobs">Observações</label>
            <soft-field
              class="custom-label"
              id="supportobs"
              v-model="state.supportobs"
              type="text"
              placeholder="Observações Gerais"
              name="supportobs"
            />
          </Form>
        </div>
        <div class="modal-footer">
          <button 
            type="button"
            class="btn btn-secondary"
            @click="stopEditing"
          >Cancelar</button>
          <button
            type="submit"
            form="myForm"
            class="btn btn-success"
            :disabled="loading"
          >
            <span v-if="loading" class="spinner-border spinner-border-sm"></span>
            <span v-else>Salvar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap";
import "vue-search-select/dist/VueSearchSelect.css";
import { mapState } from "vuex";
import SoftDateTimePicker from "../../components/SoftDateTimePicker.vue";
import formModalMixin from "../../mixins/form-modal-mixin";
import showSwal from "../../mixins/showSwal";
import { Form } from "vee-validate";
import SoftField from "../../components/SoftField.vue";
import { EquipamentsTypesOptions } from "../../constants/EquipamentsTypes";
import SoftSelectSearch from "../../components/SoftSelectSearch.vue";

const getState = (supports = {}) => ({
  id: supports?.id,
  equipament_type: supports.equipament_type ?? '', 
  user_id: supports.user_id ?? '', 
  company_id: supports.company_id ?? '', 
  date: supports.date ?? '', 
  supportobs: supports.supportobs ?? '',
});

export default {
  name: "SupportsModal",
  mixins: [formModalMixin('supports')],
  components: {
    Form,
    SoftField,
    SoftSelectSearch,
    SoftDateTimePicker
  },
  mounted() {
    this.supports_modal = new bootstrap.Modal('#supports_modal', {
      backdrop: 'static'
    });
  },
  data() {
    return {
      EquipamentsTypesOptions,
      loading: false,
      state: getState(this.supports || {}),
    };
  },
  computed: {
    ...mapState('supports', {
      open: state => state.form.open,
      supports: state => state.form.supports,
      title: state => state.form.supports?.name ?? "Novo Atendimento"
    }),
    companies() {
      return this.$store.state.companies.options;
    },
    users(){
      return this.$store.getters['users/options'];
    }
  },
  watch: {
    open(newOpen) {
      this.resetForm();
      if (newOpen) this.openSupportsModal();
      else this.closeSupportsModal();
    },
  },
  async created() {
    try {
      await this.$store.dispatch("companies/getOptions");
      await this.$store.dispatch("users/getUsers");
    } catch (error) {
      console.error("Erro ao carregar empresas:", error);
    }
  },
  methods: {
    async handleSubmit() {
      this.loading = true;
      try {
        const supportsData = {
          equipament_type: this.state.equipament_type, 
          user_id: this.state.user_id,
          company_id: this.state.company_id,
          supportcall: this.state.date,
          obs_supports: this.state.supportobs,
        };

        if (this.supports) {
          await this.$store.dispatch('supports/updateSupports', { id: this.state.id, ...supportsData });
        } else {
          await this.$store.dispatch('supports/addSupports', supportsData);
        }

        showSwal.methods.showSwal({
          type: "success",
          message: "Atendimento gerado com sucesso!",
          timer: 5000
        });
      } catch (error) {
        console.error("Erro ao salvar o suporte:", error);
        showSwal.methods.showSwal({
          type: "error",
          message: "Erro ao salvar o suporte. Tente novamente mais tarde.",
          timer: 5000
        });
      } finally {
        this.loading = false;
      }
    },
    stopEditing() {
      this.$store.dispatch('supports/stopEditing');
    },
    openSupportsModal() {
      this.supports_modal.show();
    },
    closeSupportsModal() {
      this.supports_modal.hide();
    },
    resetForm() {
      this.state = getState(this.supports || {});
      this.$refs.form.resetForm();
    },
  }
}
</script>
