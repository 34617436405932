<template>
  <div class="modal fade" id="healthcareOperator_modal" tabindex="-1" data-bs-keyboard="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal_demo_label">{{ title }}</h5>
        </div>
        <div class="modal-body">
          <Form
            id="myForm"
            ref="form"
            role="form"
            class="text-start"
            :validation-schema="schema"
            @submit="handleSubmit"
          >
            <label for="name">Nome<span class="required">*</span></label>
            <soft-field
              id="name"
              v-model="state.name"
              placeholder="Nome"
              name="name"
            />

            <soft-switch-model 
              id="active"
              name="active"
              v-model="state.active"
            >
              Ativo
            </soft-switch-model>
          </Form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="stopEditing"
          >Cancelar</button>
          <button
            type="submit"
            form="myForm"
            class="btn btn-success"
            :disabled="loading ? true : false"
          >
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span v-else>Salvar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap";
import SoftField from "@/components/SoftField.vue";
import SoftSwitchModel from "../../components/SoftSwitchModel.vue";
import showSwal from "../../mixins/showSwal";
import * as yup from "yup";
import { mapState } from "vuex";
import formModalMixin from "../../mixins/form-modal-mixin";
import { Form } from "vee-validate";

const getState = (healthcareOperator) => ({
  id: healthcareOperator?.id,
  name: healthcareOperator?.name ?? '',
  active: healthcareOperator?.active ?? true,
})

export default {
  name: "HealthcareOperatorModal",
  mixins: [ formModalMixin('healthcareOperators') ],
  components: {
    SoftField,
    SoftSwitchModel,
    Form
  },
  mounted() {
    this.healthcareOperator_modal = new bootstrap.Modal('#healthcareOperator_modal', {
      backdrop: 'static'
    });
  },
  data() {
    const schema = yup.object().shape({
      name: yup.string().required().label('Nome'),
    });

    return {
      schema,
      loading: false,
      state: getState(null)
    }
  },
  computed: {
    ...mapState('healthcareOperators', {
      open: state => state.form.open,
      healthcareOperator: state => state.form.healthcareOperator,
      title: state => state.form.healthcareOperator?.name ?? "Nova Operadora de Saúde",
    }),
    
  },
  watch: {
    open (newOpen) {
      this.resetForm()
      if (newOpen) this.openHealthcareOperatorModal();
      else this.closeHealthcareOperatorModal();
    },
  },
  methods: {
    async handleSubmit() {
      this.loading = true
      try {
        if (this.healthcareOperator) 
        await this.$store.dispatch('healthcareOperators/updateHealthcareOperator', this.state);
        else 
        await this.$store.dispatch('healthcareOperators/addHealthcareOperator', this.state);
        if (this.error == null){
          showSwal.methods.showSwal({
              type:"success",
              message: "Operador de Saúde cadastrado com sucesso!",
              timer: 5000
          })
        }
        this.loading = false

      } catch (error) {
        
        this.loading = false;
      }
    },
    stopEditing() {
      this.$store.dispatch('healthcareOperators/stopEditing');
    },
    openHealthcareOperatorModal() {
      this.healthcareOperator_modal.show()
    },
    closeHealthcareOperatorModal() {
      this.healthcareOperator_modal.hide()
    },
    resetForm() {
      this.state = getState(this.healthcareOperator)
      this.$refs.form.resetForm();
    },
  },
}
</script>