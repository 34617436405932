import leadsService from "../services/leads.service";
import { parseFormError } from "../services/utils";

const initialState = {
  leads: [],
  loading: false,
  page: 1,
  lastPage: 1,
  perPage: 1,
  total: 0,
  form: {
    open: false,
    lead: null,
    error: null,
  }
};

export const leads = {
  namespaced: true,
  state: initialState,
  actions: {
    async getLeads({ commit, state }) {
      commit("SET_LOADING", true);
      const response = await leadsService.getLeads(state.page);
      commit("SET_LEADS", response.data);
      commit("SET_PAGE", response.current_page);
      commit("SET_TOTAL", response.total);
      commit("SET_PER_PAGE", response.per_page);
      commit("SET_LAST_PAGE", response.last_page);
    },
    async getAllLeads({ commit }) {
      commit("SET_LOADING", true);
      const response = await leadsService.getAllLeads();
      commit("SET_LEADS", response);
      commit("SET_LOADING", false);
    },
    changeToPage({ commit, dispatch, state }, page) {
      if (page >= 1 && page <= state.lastPage) {
        commit("SET_PAGE", page);
        dispatch("getLeads");
      }
    },
    startAdding({ commit }) {
      const value = { open: true, lead: null };
      commit("SET_FORM", value);
    },
    async addLead({ dispatch, commit }, lead) {
      try {
        await leadsService.addLead({
          name: lead.name,
          email: lead.email,
          phone: lead.phone,
          state: lead.state,    
          city: lead.city,      
          zone: lead.zone,
          company_id: lead.company_id,      
          source: lead.source,
          funnel_stage: lead.funnel_stage,
          assigned_user_id: lead.assigned_user_id,
          notes: lead.notes
        });
        dispatch("stopEditing");
        dispatch("getLeads");
      } catch (error) {
        commit('SET_FORM_ERROR', parseFormError(error));
      }
    },
    async startEditing({ commit }, lead) {
      const value = { open: true, lead: lead };
      commit("SET_FORM", value);
    },
    async updateLead({ dispatch, commit }, lead) {
      try {
        await leadsService.updateLead({
          id: lead.id,
          name: lead.name,
          email: lead.email,
          phone: lead.phone,
          state: lead.state,    
          city: lead.city,     
          zone: lead.zone,
          company_id: lead.company_id,       
          source: lead.source,
          funnel_stage: lead.funnel_stage,
          assigned_user_id: lead.assigned_user_id,
          notes: lead.notes
        });
        dispatch("stopEditing");
        dispatch("getLeads");
      } catch (error) {
        commit('SET_FORM_ERROR', parseFormError(error));
      }
    },
    async stopEditing({ commit }) {
      const value = { open: false, lead: null };
      commit("SET_FORM", value);
    },
  },
  mutations: {
    SET_LEADS(state, leads) {
      state.leads = leads;
      state.loading = false;
    },
    SET_PAGE(state, page) {
      state.page = page;
    },
    SET_TOTAL(state, total) {
      state.total = total;
    },
    SET_PER_PAGE(state, per_page) {
      state.perPage = per_page;
    },
    SET_LAST_PAGE(state, last_page) {
      state.lastPage = last_page;
    },
    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_FORM(state, { open, lead }) {
      state.form.open = open;
      state.form.lead = lead;
    },
    SET_FORM_ERROR(state, error) {
      state.form.error = error;
    },
    resetState(state) {
      state.leads = [];
      state.loading = false;
      state.form = {
        open: false,
        lead: null,
        error: null,
      };
    }
  },
  getters: {
    total(state) {
      return state.leads.length;
    },
  },
};
