import api from "./axios";

export default {
    async getLeads(page = 1) {
        const response = await api.get(`/leads?page=${page}`);
        return response.data;
    },

    async getAllLeads() {
        const response = await api.get(`/leads?all=true`);
        return response.data;
    },

    async addLead(lead) {
        const response = await api.post('/leads', lead);
        return response.data;
    },

    async updateLead(lead) {
        const response = await api.put(`/leads/${lead.id}`, lead);
        return response.data;
    },

}
