import api from "./axios";

export default {
    async getEquipaments(page = 1, per_page = 10) {
        const response = await api.get(`/equipaments?page=${page}&per_page=${per_page}`);
        return response.data;
    },
    async addEquipaments(equipaments) {
        const formData = new FormData();
        for (const key in equipaments) {
            formData.append(key, equipaments[key]);
        }
        const response = await api.post('/equipaments', formData, {
            headers: { "Content-Type": "multipart/form-data" }
        })

        return response.data;
    },
    async updateEquipaments(equipaments) {
        const formData = new FormData();
        for (const key in equipaments) {
            formData.append(key, equipaments[key]);
        }
        const response = await api.put(`/equipaments/${equipaments.id}`, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        })

        return response.data;
    },
    async findEquipaments(query, page= 1, per_page = 10) {
        const response = await api.get(`/equipaments`, {
            params: {
                query: query,
                page: page,
                per_page: per_page
            }
        });
        return response.data;
    },
}